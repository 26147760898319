<template>
    <div>
        <CompContent title="畅联通">
            <template #operate>
                <Button type="info" icon="md-refresh" @click="load">刷新数据</Button>
            </template>

            <div class="module-box">
                <div class="item-box">
                    <div class="title-box">
                        <div class="title">畅联组</div>
                        <div class="operation" @click="route.jump('/groupeasycombination')">管理畅联组</div>
                    </div>
                    <div class="tools-box">
                        <div class="button-box">
                            <Button class="item" @click="route.jump('/groupeasyannouncement')">公告已读统计</Button>
                            <Button class="item" @click="route.jump('/groupeasyforward')">确认转发统计</Button>
                        </div>
                        <Button type="primary" class="operation" @click="onMassNotice">发公告</Button>
                    </div>
                    <div class="content">
                        <div class="title-box">
                            <div class="title">畅联组公告</div>
                            <div class="hint">（显示未读公告列表）</div>
                            <div class="quantity">共 {{ announcements ? announcements.length : 0 }} 条</div>
                            <div class="operation" @click="$refs.announcement_modal.display()">全部</div>
                        </div>
                        <div class="group-list-box group-announcements">
                            <div class="item" v-for="(item, index) in announcements" :key="index" @click="onOpenAnnouncement(item)">
                                <div class="icon-view">
                                    <img class="image" src="https://liefeng.oss-cn-shenzhen.aliyuncs.com/PROD/zjsm/20240326/158741857.jpg" />
                                    <div class="num">{{ item.unreadNum || "" }}</div>
                                </div>
                                <div class="name-view">
                                    <div class="name">{{ item.groupName }}</div>
                                    <div class="time-view">{{ item.lastTime ? $core.formatDate(new Date(item.lastTime), "yyyy-MM-dd") : "" }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content">
                        <div class="title-box">
                            <div class="title">我的消息</div>
                            <div class="hint">（显示未读消息列表）</div>
                            <div class="quantity">共 {{ messages ? messages.length : 0 }} 条</div>
                            <div class="operation" @click="$refs.message_modal.display()">全部</div>
                        </div>
                        <div class="group-list-box personal-messages">
                            <div class="content-view" v-if="messages && messages.length > 0">
                                <div v-for="(item, index) in messages" :key="index" class="content" @click="onOpenMessageDetail(item)">
                                    <div class="left">
                                        <!-- <AtAvatar class="avatar-image" circle :text='item.nickname'></AtAvatar> -->
                                        <img class="avatar-image" :src="item.avatarUrl" />
                                        <div class="num" v-if="item.unReadCount && item.unReadCount >= 1">{{ item.unReadCount }}</div>
                                    </div>
                                    <div class="right">
                                        <div class="top">
                                            <div class="orgName">{{ item.nickname || "未知" }}</div>
                                        </div>
                                        <!-- 纯文字 -->
                                        <div class="text-content" v-if="item.lastMsg && JSON.stringify(item.lastMsg) != '{}' && item.lastMsg.msgType == '1'">{{ item.lastMsg.msg || "暂无" }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item-box">
                    <div class="title-box">
                        <div class="title">畅联群</div>
                        <div class="operation" @click="route.jump('/groupeasygroup')">管理畅联群</div>
                    </div>
                    <div class="content">
                        <div class="title-box">
                            <div class="title">我的群消息</div>
                            <div class="hint">（显示未读消息群列表）</div>
                            <div class="quantity">共 {{ groups ? groups.length : 0 }} 条</div>
                            <Button type="primary" class="operation-btn" @click="onMassGroup">群发消息</Button>
                        </div>
                        <div class="group-list-box group">
                            <div class="liefeng-im-groups-group" v-for="(data, index) in groups" :key="'group' + index" @click="onOpenGroupDetail(data)">
                                <div class="icon-view">
                                    <img class="img" :src="data.image || 'https://liefeng.oss-cn-shenzhen.aliyuncs.com/PROD/zjsm/20240326/158741857.jpg'" />
                                    <div class="num">{{ data.unreadCount || "" }}</div>
                                </div>
                                <div class="content">
                                    <div class="content-name">
                                        <div class="group-name">{{ data.groupName }}</div>
                                        <div class="extra-text">{{ data.time }}</div>
                                    </div>
                                    <div class="group-note">{{ data.note }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CompContent>

        <CompModal title="畅联通" ref="modal" width="400px" @on-close="iframeUrl = null">
            <iframe v-if="iframeUrl" :src="iframeUrl" width="100%" height="100%" frameborder="0" style="display: block;"></iframe>
        </CompModal>

        <CompModal title="畅联组公告" ref="announcement_modal" width="400px">
            <div class="group-list-box group-announcements">
                <div class="item" v-for="(item, index) in allAnnouncements" :key="index" @click="onOpenAnnouncement(item)">
                    <div class="icon-view">
                        <img class="image" src="https://liefeng.oss-cn-shenzhen.aliyuncs.com/PROD/zjsm/20240326/158741857.jpg" />
                        <div class="num">{{ item.unreadNum || "" }}</div>
                    </div>
                    <div class="name-view">
                        <div class="name">{{ item.groupName }}</div>
                        <div class="time-view">{{ item.lastTime ? $core.formatDate(new Date(item.lastTime), "yyyy-MM-dd") : "" }}</div>
                    </div>
                </div>
            </div>
        </CompModal>

        <CompModal title="我的消息" ref="message_modal" width="400px">
            <div class="group-list-box personal-messages">
                <div class="content-view" v-if="allMessages && allMessages.length > 0">
                    <div v-for="(item, index) in allMessages" :key="index" class="content" @click="onOpenMessageDetail(item)">
                        <div class="left">
                            <!-- <AtAvatar class="avatar-image" circle :text='item.nickname'></AtAvatar> -->
                            <img class="avatar-image" :src="item.avatarUrl" />
                            <div class="num" v-if="item.unReadCount && item.unReadCount >= 1">{{ item.unReadCount }}</div>
                        </div>
                        <div class="right">
                            <div class="top">
                                <div class="orgName">{{ item.nickname || "未知" }}</div>
                            </div>
                            <!-- 纯文字 -->
                            <div class="text-content" v-if="item.lastMsg && JSON.stringify(item.lastMsg) != '{}' && item.lastMsg.msgType == '1'">{{ item.lastMsg.msg || "暂无" }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </CompModal>
    </div>
</template>

<script>
import proxy from "@/api/proxy.js"
import Route from "../../communitymobilization/entity/Route"
import CompContent from "../../customform/components/CompContent.vue"
import CompModal from "../../residentdatabase/components/CompModal.vue"

export default {
    components: {
        CompContent,
        CompModal,
    },

    data() {
        return {
            route: Route,
            announcements: null,
            allAnnouncements: null,
            groups: null,
            messages: null,
            allMessages: null,
            iframeUrl: null,
        }
    },

    watch: {
        iframeUrl(v) {
            v && this.$refs.modal.display()
        },
    },

    created() {
        this.load()
    },

    methods: {
        async load() {
            const loading = this.$Message.loading({
                content: "加载中...",
                duration: 0,
            })

            Promise.all([this.getAnnouncement(), this.getGroups(), this.getMessages()]).then(() => {
                loading()
            })
        },

        /**
         * 获取公告
         */
        async getAnnouncement() {
            await this.$get("/gateway/chat/app/chat/group/queryUserGroupAfficheCount").then(res => {
                if (res.code !== "200") {
                    return this.$Message.error(res.desc || res.msg || "请求失败，请稍后再试！")
                }
                this.allAnnouncements = res.dataList.sort((a, b) => b.unreadNum - a.unreadNum)
                // 过滤已读且按顺序排序
                this.announcements = res.dataList.filter(v => v.unreadNum > 0).sort((a, b) => b.unreadNum - a.unreadNum)
            })
        },

        /**
         * 获取个人消息
         */
        async getMessages() {
            await this.$get("/gateway/chat/app/chat/onlyUser/getUserList").then(res => {
                if (res.code !== "200") {
                    return this.$Message.error(res.desc || res.msg || "请求失败，请稍后再试！")
                }
                this.allMessages = res.dataList.map(item => {
                    if (item.lastMessage) {
                        item.content = JSON.parse(item.lastMessage.content)
                    }
                    return item
                })
                // 过滤已读且按顺序排序
                this.messages = this.allMessages.filter(v => v.unReadCount > 0)
            })
        },

        /**
         * 获取群消息
         */
        async getGroups() {
            await this.$post("/gateway/sy-chat/chat/app/chat/groupCreate/selectByChatGroupPage", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode: "liefeng",
                account: parent.vue.loginInfo.userinfo.account,
                appCode: "suiyue",
                orgCode: null,
                projectCode: "lwq045",
                groupCode: null,
                page: 1,
                pageSize: 1000,
                // orgCode: parent.vue.loginInfo.userinfo.orgCode,
                // custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                // oemCode: "liefeng",
                // account: parent.vue.loginInfo.userinfo.account,
                // appCode: "suiyue",
                // projectCode: "lwq045",
            }).then(res => {
                if (res.code !== "200") {
                    return this.$Message.error(res.desc || res.msg || "请求失败，请稍后再试！")
                }
                this.groups = res.dataList
                    .filter(v => v.unreadCount > 0)
                    .map(item => {
                        if (item.lastMessage) {
                            item.time = this.getTime(item.lastMessage.gmtCreate)
                            item.note =
                                (item.lastMessage.nickname ? item.lastMessage.nickname + "：" : "") +
                                (item.lastMessage.msgType == "1"
                                    ? item.lastMessage.msg
                                    : item.lastMessage.msgType == "2"
                                    ? "[图片]"
                                    : item.lastMessage.msgType == "3"
                                    ? "[语音]"
                                    : item.lastMessage.msgType == "4"
                                    ? "[视频]"
                                    : item.lastMessage.msgType == "5"
                                    ? "[链接]"
                                    : item.lastMessage.msgType == "6"
                                    ? "[文件]"
                                    : item.lastMessage.msgType == "7"
                                    ? "[表情]"
                                    : item.lastMessage.msgType == "8"
                                    ? "[群公告]"
                                    : item.lastMessage.msgType == "9"
                                    ? "[富文本]"
                                    : "[其它]")
                        }

                        if (item.chatGroupManager && item.chatGroupManager.find(({ managerId } = item2) => managerId === parent.vue.loginInfo.userinfo.custGlobalId)) {
                            this.isManager = true
                        }

                        return item
                    })
            })
        },

        onOpenAnnouncement(item) {
            this.iframeUrl = `${proxy["/custom"].target}/#/pages/im/group/groupnoticelist?groupCode=${item.groupId}&token=${window.sessionStorage.getItem("accessToken")}`
        },

        /**
         * 打开个人聊天窗口
         */
        onOpenMessageDetail(item) {
            const loading = this.$Message.loading({
                content: "连接中...",
                duration: 0,
            })

            this.$get("/gateway/chat/app/chat/onlyUser/flushUser", {
                onlyUserId: item.custGlobalId,
            })
                .then(res => {
                    if (res.code !== "200" || !res.data) {
                        return this.$Message.error(res.desc || res.msg || "建立连接失败，请稍后再试！")
                    }

                    this.iframeUrl = `${proxy["/custom"].target}/#/pages/im/person/chat?groupCode=${res.data}&groupName=${item.nickname}&businessType=1&businessCode=2&custGlobalId=${item.custGlobalId}&token=${window.sessionStorage.getItem("accessToken")}`
                })
                .finally(() => {
                    loading()
                })
        },

        /**
         *  打开沟通群详情
         */
        onOpenGroupDetail(item) {
            this.$core.openLayerFrame({
                type: 2,
                title: `${item.groupName}`,
                content: `/page#/groupdetail?groupId=${item.groupId}&groupName=${item.groupName}`,
                area: ["100%", "100%"],
            })
        },

        /**
         * 群发消息
         */
        onMassGroup() {
            this.$core.openLayerFrame({
                type: 2,
                title: `群发消息`,
                content: `/page#/groupedit?sendType=0&name=${"群发消息"}`,
                area: ["100%", "100%"],
            })
        },

        /**
         * 发公告
         */
        onMassNotice() {
            this.$core.openLayerFrame({
                type: 2,
                title: `群发公告`,
                content: `/page#/groupedit?sendType=1&name=${"群发公告"}`,
                area: ["100%", "100%"],
            })
        },

        getTime(time) {
            if (time == null) return ""
            var timestr = ""
            if (time > 1000000) {
                var min = new Date().getTime() - time
                min = Math.ceil(min / (1000 * 60))
                if (min < 10) {
                    timestr = "刚刚"
                } else if (min >= 10 && min < 60) {
                    timestr = min + "分钟前"
                } else if (min >= 60 && min < 60 * 24) {
                    var hour = Math.ceil(min / 60)
                    timestr = hour + "小时前"
                } else if (min >= 60 * 24 && min < 60 * 24 * 30) {
                    var day = Math.ceil(min / (60 * 24))
                    timestr = day + "天前"
                } else if (min >= 60 * 24 * 30 && min < 60 * 24 * 365) {
                    var day = Math.ceil(min / (60 * 24 * 30))
                    timestr = day + "月前"
                } else if (min >= 60 * 24 * 365) {
                    var day = Math.ceil(min / (60 * 24 * 365))
                    timestr = day + "年前"
                }
            }
            return timestr
        },
    },
}
</script>

<style lang="less" scoped>
.module-box {
    min-width: 500px;
    padding: 10px 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;

    > .item-box {
        margin: 10px;
        padding: 20px;
        width: calc(50% - 20px);
        max-width: 900px;
        background: #f5f5f5;
        border-radius: 10px;
        box-sizing: border-box;

        @media (max-width: 900px) {
            width: calc(100% - 20px);
        }

        > .title-box {
            display: flex;
            align-items: flex-end;
            line-height: 1;

            > .title {
                font-size: 20px;
                font-weight: bold;
            }

            > .operation {
                position: relative;
                margin-left: 15px;
                font-size: 15px;
                color: rgb(20, 178, 108);
                padding-right: 15px;
                text-align: right;
                cursor: pointer;
                font-weight: bold;

                &::after {
                    content: ">";
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 14px;
                    color: rgb(20, 178, 108);
                    font-family: "SimSun";
                }
            }
        }

        > .tools-box {
            margin: 15px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .button-box {
                display: flex;
                align-items: center;

                .item {
                    margin: 0 5px 0 0;
                }
            }
        }

        > .content {
            position: relative;
            margin: 15px 0 0;
            background: #fff;
            border-radius: 10px;
            overflow-y: auto;
            max-height: calc(100vh - 180px);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

            > .title-box {
                position: sticky;
                top: 0;
                padding: 15px 15px 0 15px;
                display: flex;
                align-items: center;
                background: #fff;
                z-index: 10;

                .operation {
                    position: relative;
                    margin-left: auto;
                    padding-right: 15px;
                    text-align: right;
                    font-size: 14px;
                    color: #1890ff;
                    cursor: pointer;

                    &::after {
                        content: ">";
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 14px;
                        color: #1890ff;
                        font-weight: bold;
                        font-family: "SimSun";
                    }
                }

                .operation-btn {
                    margin-left: auto;
                }
            }
        }
    }
}

.group-list-box {
    padding: 0 15px;

    &.group-announcements {
        .item {
            cursor: pointer;
            padding: 15px 5px;
            display: flex;
            justify-content: flex-start;
            border-bottom: 1px solid #f3f3f3;

            &:nth-last-child(1) {
                border-bottom: none;
            }

            .icon-view {
                position: relative;

                .image {
                    border-radius: 5px;
                    width: 60px;
                    height: 60px;
                }

                .num {
                    // background: red;
                    // border-radius: 50%;
                    padding: 0 7px;
                    right: -10px;
                    top: -10px;
                    position: absolute;
                    color: #fff;

                    background: #fa5151;
                    border-radius: 9px;
                    text-align: center;
                    font-size: 12px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    // line-height: @height;
                    // color: #ffffff;
                    box-sizing: border-box;
                    min-width: 18px;
                    // padding: 0 10px;
                }
            }

            .name-view {
                width: 60%;
                margin-left: 20px;
                font-size: 16px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                .time-view {
                    font-size: 13px;
                    color: #a3a1a1;
                }
            }
        }
    }

    &.personal-messages {
        .content-view {
            padding: 5px 0;
            color: #333;

            .content {
                cursor: pointer;
                display: flex;
                padding: 10px;
                border-bottom: 1px solid #f3f3f3;

                &:last-child {
                    border-bottom: none;
                }

                .left {
                    position: relative;

                    .avatar-image {
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        margin-top: 5px;
                        border-radius: 50%;
                    }

                    .num {
                        padding: 0px 7px;
                        right: -10px;
                        top: -10px;
                        position: absolute;
                        color: #fff;

                        background: #fa5151;
                        border-radius: 9px;
                        text-align: center;
                        font-size: 12px;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                        // line-height: @height;
                        // color: #ffffff;
                        box-sizing: border-box;
                        min-width: 18px;
                    }
                }

                .right {
                    flex: 1;
                    margin-left: 15px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;

                    .top {
                        display: flex;
                        justify-content: space-between;
                        color: rgb(136, 136, 136);
                    }

                    .text-content {
                        width: 100%;
                        word-wrap: normal;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }

    &.group {
        .liefeng-im-groups-group {
            cursor: pointer;
            padding: 10px 0;
            display: flex;
            justify-content: space-around;
            align-items: flex-start;
            width: 100%;
            border-bottom: 1px solid #f3f3f3;
            overflow: hidden;

            &:last-child {
                border-bottom: none;
            }

            .AtBadge {
                top: 10px;
            }

            .icon-view {
                position: relative;

                .img {
                    border-radius: 5px;
                    width: 60px;
                    height: 60px;
                }

                .num {
                    // background: red;
                    // border-radius: 50%;
                    padding: 0 7px;
                    right: -10px;
                    top: -7px;
                    position: absolute;
                    color: #fff;

                    background: #fa5151;
                    border-radius: 9px;
                    text-align: center;
                    font-size: 12px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    // line-height: @height;
                    // color: #ffffff;
                    box-sizing: border-box;
                    min-width: 18px;
                    // padding: 0 10px;
                }
            }

            .content {
                flex: 1;
                display: flex;
                flex-direction: column;
                margin: 0 0 0 20px;
                overflow: hidden;
                .content-name {
                    width: 100%;
                    display: flex;
                    .group-name {
                        flex: 1;
                        font-size: 16px;
                        color: #555;
                        font-weight: bold;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        margin: 5px 0;
                    }
                    .extra-text {
                        flex-shrink: 0;
                        margin: 5px;
                        font-size: 12px;
                        text-align: right;
                        color: #888;
                    }
                }

                .group-note {
                    width: 100%;
                    font-size: 12px;
                    color: #888;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    margin: 2px 0;
                }
            }
        }
    }

    &:empty::after {
        content: "暂无未读消息";
        padding: 130px 20px;
        color: #888;
        text-align: center;
        font-size: 14px;
        display: block;
    }
}
</style>
